import Swal from 'sweetalert2'
import { store } from '@/store'
import withReactContent from 'sweetalert2-react-content'
import { NotificationItemComponent, NotificationItemProps } from '@/components/notifications/NotificationItemComponent'
import { AMOUNT_DECIMALS, PRICE_DECIMALS, TOAST_TIMEOUT } from '@/utils/constants'
import { addNotification } from '@/store/notificationSlice'
import { createRoot } from 'react-dom/client'
import { Asset, BalanceInput, Order, OrderState, OrderType, User, UserRole } from '@/graphql/generated/graphql-request'
import { OrderSide, TradeTypes } from '@/constants/trade'
import { getOrderTypeName } from '@/utils/tradeUtils'
import { formatNumber } from '@/utils/numberUtils'

export const toast = withReactContent(Swal)

export type NotificationItem = {
  id: string
  title: string
  params: FixMe
  notificationStatus: NotificationStatus
  notificationType: NotificationType
  timestamp: number
}

export enum NotificationStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export enum NotificationType {
  ADD_LIQUIDITY = 'addLiquidity',
  ADD_REMOVE_BALANCE = 'addRemoveBalance',
  ASSET_OPERATIONS = 'assetOperations',
  CREATE_ASSET = 'createAsset',
  CREATE_ORDER = 'createOrder',
  CREATE_POOL = 'createPool',
  DISPOSABLE = 'discardable',
  ORDER_CANCEL = 'orderCancel',
  ORDER_EXECUTED_OR_CANCELED = 'orderExecutedOrCanceled',
  REMOVE_LIQUIDITY = 'removeLiquidity',
  ROLES = 'roles',
  SIMPLE = 'simple',
  TOKEN = 'token',
  REPAY = 'repay',
}

export const generateTokenNotification = (token: string) => {
  return (
    <div className={'flex flex-col gap-1'}>
      <div className='text-sm'>Token copied to clipboard</div>
      <div className='text-sm flex flex-row gap-1'>
        <div>Token:</div>
        <div className={'text-primary'}>{token}</div>
      </div>
    </div>
  )
}

export const generateRolesNotification = (roles: UserRole[], user: User) => {
  return (
    <div className={'flex flex-col gap-1'}>
      <div className={'text-sm flex flex-row gap-1'}>
        <div>Role updated to:</div>
        <div className={'text-primary'}>{roles[roles.length - 1]}</div>
      </div>

      <div className={'text-sm flex flex-row gap-1'}>
        <div>User:</div>
        <div className={'text-primary'}>{user.email}</div>
      </div>
    </div>
  )
}

export const generateAddRemoveBalanceNotification = (
  selectedAsset: Asset,
  text: string,
  amount: string,
  isAdding: boolean,
) => {
  const displayAmount = Number.isNaN(Number(amount)) ? 0 : Number(amount) / Math.pow(10, selectedAsset?.decimals ?? 0)
  return (
    <div className={'flex flex-col'}>
      <div className={'text-sm'}>{text}</div>
      <div className={'text-sm flex flex-row gap-1 mt-2'}>
        <div>Amount:</div>
        <div className={isAdding ? 'text-success' : 'text-danger'}>{formatNumber(displayAmount, AMOUNT_DECIMALS)}</div>
      </div>
      <div className={'text-sm flex flex-row gap-1'}>
        <div>Asset:</div>
        <div className={'text-primary'}>{selectedAsset?.symbol}</div>
      </div>
    </div>
  )
}

export const generateAssetOperationsNotification = (text: string, amount: string, asset: Asset | undefined) => {
  const displayAmount = Number.isNaN(Number(amount)) ? 0 : Number(amount) / Math.pow(10, asset?.decimals ?? 0)

  return (
    <div className={'flex flex-col'}>
      <div className={'text-sm'}>{text}</div>
      <div className={'text-sm flex flex-row gap-1 mt-2'}>
        <div>Amount:</div>
        <div className={'text-success'}>{formatNumber(displayAmount, AMOUNT_DECIMALS)}</div>
      </div>
      <div className={'text-sm flex flex-row gap-1'}>
        <div>Asset:</div>
        <div className={'text-primary'}>{asset?.symbol}</div>
      </div>
    </div>
  )
}

export const generateCreateAssetNotification = (symbol: string) => {
  return (
    <div className={'flex flex-col'}>
      <div className={'text-sm'}>Asset created successfully</div>
      <div className={'text-sm flex flex-row gap-1 mt-2'}>
        <div>Asset:</div>
        <div className={'text-primary'}>{symbol}</div>
      </div>
    </div>
  )
}

export const generateCreatePoolNotification = (
  baseAssetAmount: string,
  quoteAssetAmount: string,
  baseAssetID: string,
  quoteAssetID: string,
  swapFee: string,
) => {
  return (
    <div className={'flex flex-col'}>
      <div className={'text-sm'}>Pool created successfully</div>
      <div className={'text-sm flex flex-row gap-1 mt-2'}>
        <div>Base Asset:</div>
        <div className={'text-primary'}>{baseAssetID}</div>
      </div>
      <div className={'text-sm flex flex-row gap-1'}>
        <div>Amount:</div>
        <div className={'text-primary'}>{formatNumber(baseAssetAmount, AMOUNT_DECIMALS)}</div>
      </div>

      <div className={'text-sm flex flex-row gap-1 mt-2'}>
        <div>Quote Asset:</div>
        <div className={'text-primary'}>{quoteAssetID}</div>
      </div>
      <div className={'text-sm flex flex-row gap-1'}>
        <div>Amount:</div>
        <div className={'text-primary'}>{formatNumber(quoteAssetAmount, AMOUNT_DECIMALS)}</div>
      </div>

      <div className={'text-sm flex flex-row gap-1 mt-2'}>
        <div>Swap Fee:</div>
        <div className={'text-primary'}>{swapFee}</div>
      </div>
    </div>
  )
}

export const generateOrderCancelNotification = (orderID: string) => {
  return (
    <div className={'flex flex-col'}>
      <div className={'text-sm'}>Order is set to be cancelled</div>
      <div className={'text-sm flex flex-row gap-1'}>
        <div>ID:</div>
        <div className={'text-primary'}>{orderID}</div>
      </div>
    </div>
  )
}

export const generateAddLiquidityNotification = (pool: string, assets: BalanceInput[], allAssets: Asset[]) => {
  return (
    <div className={'flex flex-col gap-2'}>
      <div className={'text-sm'}>Liquidity added successfully</div>
      <div className={'text-sm flex flex-row gap-1 mt-2'}>
        <div>Pool:</div>
        <div className={'text-primary'}>{pool}</div>
      </div>
      {assets.map((asset) => {
        const assetData = allAssets.find((a) => a.uid === asset.assetID)
        const numberAmount = Number.isNaN(Number(asset.amount))
          ? 0
          : Number(asset.amount) / Math.pow(10, assetData?.decimals ?? 0)

        return (
          <div className={'flex flex-col'}>
            <div key={asset.assetID} className={'text-sm flex flex-row gap-1'}>
              <div>Asset:</div>
              <div className={'text-primary'}>{assetData?.symbol}</div>
            </div>
            <div key={asset.assetID} className={'text-sm flex flex-row gap-1'}>
              <div>Amount:</div>
              <div>{formatNumber(numberAmount, AMOUNT_DECIMALS)}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const generateRemoveLiquidityNotification = (poolId: string, shares: string) => {
  return (
    <div className={'flex flex-col'}>
      <div className={'text-sm'}>Liquidity removed successfully</div>
      <div className={'text-sm flex flex-row gap-1 mt-2'}>
        <div>Pool:</div>
        <div className={'text-primary'}>{poolId}</div>
      </div>
      <div className={'text-sm flex flex-row gap-1'}>
        <div>Shares:</div>
        <div>{formatNumber(shares, AMOUNT_DECIMALS)}</div>
      </div>
    </div>
  )
}

export const generateRepayNotification = (asset: Asset, amount: string) => {
  return (
    <div className={'flex flex-col'}>
      <div className={'text-sm'}>Repay successfully</div>
      <div className={'text-sm flex flex-row gap-1 mt-2'}>
        <div>Asset:</div>
        <div className={'text-primary'}>{asset?.symbol}</div>
      </div>
      <div className={'text-sm flex flex-row gap-1'}>
        <div>Amount:</div>
        <div>{formatNumber(amount, AMOUNT_DECIMALS)}</div>
      </div>
    </div>
  )
}

//"Pool Name" "Order Type" submitted for "Amt" "Base Asset" at Limit "Limit Px" "Quote Asset"
const generateNewOrderNotification = (
  orderID: string,
  selectedTradeType: TradeTypes,
  selectedOrderSide: OrderSide,
  priceValue: string,
  leverageValue: string,
  baseAsset: Asset,
  quoteAsset: Asset,
  size: string,
  advancedType: string,
) => {
  const orderTypeString = getOrderTypeName(selectedTradeType, selectedOrderSide)
  const selectedTradeTypeString = selectedTradeType === TradeTypes.LIMIT ? 'Limit' : 'Market'
  const leverage = Number(leverageValue)
  const totalSizeWithLeverage = Number(size) * leverage

  const orderIDblock = (
    <div className={'text-sm flex flex-row gap-1'}>
      <div>ID:</div>
      <div className={'text-primary'}>{orderID}</div>
    </div>
  )

  const poolName = (
    <div className={'flex flex-row gap-1'}>
      <div>Pool:</div>
      <div className={'text-primary'}>
        {baseAsset?.symbol} - {quoteAsset?.symbol}
      </div>
    </div>
  )
  const orderType = (
    <div className={'flex flex-row gap-1'}>
      <div>Order Type:</div>
      <div className={selectedOrderSide === OrderSide.BUY ? 'text-success' : 'text-danger'}>{orderTypeString}</div>
    </div>
  )

  const advancedOrderType = (
    <div className={'flex flex-row gap-1'}>
      <div>Advanced Type:</div>
      <div>{advancedType}</div>
    </div>
  )

  const submitted = (
    <div className={'flex flex-row gap-1'}>
      <div>Submitted for</div>
      <div>{formatNumber(totalSizeWithLeverage, AMOUNT_DECIMALS)}</div>
      <div>{baseAsset?.symbol}</div>
    </div>
  )

  const priceContent = (
    <div className={'flex flex-row gap-1'}>
      <div>At {selectedTradeTypeString}</div>{' '}
      <div className={'flex flex-row gap-1'}>
        <div>{formatNumber(priceValue, PRICE_DECIMALS)} </div>
        <div> {quoteAsset?.symbol}</div>
      </div>
    </div>
  )

  const leverageContent = (
    <div className={'flex flex-row gap-1'}>
      <div>Leverage:</div>
      <div>{leverage}x</div>
    </div>
  )

  return (
    <div className={'flex flex-col text-sm gap-1'}>
      <div>{orderIDblock}</div>
      <div>{poolName}</div>
      <div>{orderType}</div>
      <div>{advancedOrderType}</div>
      <div>{submitted}</div>
      <div>{priceContent}</div>
      {leverage > 1 && <div>{leverageContent}</div>}
    </div>
  )
}

const generateSimpleNotification = (params: FixMe) => {
  if (typeof params === 'string') {
    return <div>{params}</div>
  }

  return <div>{JSON.stringify(params)}</div>
}

const generateOrderStateChanged = (
  order: Order,
  baseAsset: Asset,
  quoteAsset: Asset,
  priceDisplayMultiplier: number,
) => {
  const poolName = `${baseAsset?.symbol} - ${quoteAsset?.symbol}`
  const orderStateText = order.state === OrderState.Executed ? 'Order Executed' : 'Order Cancelled'
  const selectedOrderSide =
    order.type === OrderType.LimitBuy || order.type === OrderType.MarketBuy ? OrderSide.BUY : OrderSide.SELL
  const selectedTradeTypeString = getOrderTypeName(order.type)
  const displayAmount = Number.isNaN(Number(order.amountIn))
    ? 0
    : Number(order.amountIn) / Math.pow(10, baseAsset?.decimals ?? 0)

  const displayPrice = order.price * priceDisplayMultiplier

  return (
    <div className={'flex flex-col gap-1'}>
      <div className={'text-sm'}>{orderStateText}</div>
      <div className={'text-sm flex flex-row gap-1'}>
        <div>ID:</div>
        <div className={'text-primary'}>{order.orderID}</div>
      </div>
      <div className={'text-sm flex flex-row gap-1'}>
        <div>Pool:</div>
        <div className={'text-primary'}>{poolName}</div>
      </div>
      <div className={'text-sm flex flex-row gap-1'}>
        <div>Order Type:</div>
        <div className={selectedOrderSide === OrderSide.BUY ? 'text-success' : 'text-danger'}>
          {getOrderTypeName(order.type)}
        </div>
      </div>
      <div className={'flex flex-row gap-1'}>
        <div>Advanced Type:</div>
        <div>{order.advancedType}</div>
      </div>
      <div className={'text-sm flex flex-row gap-1'}>
        <div>Submitted for</div>
        <div>{formatNumber(displayAmount, AMOUNT_DECIMALS)}</div>
        <div>{baseAsset?.symbol}</div>
      </div>
      <div className={'text-sm flex flex-row gap-1'}>
        <div>At {selectedTradeTypeString}</div>{' '}
        <div className={'flex flex-row gap-1'}>
          <div>{formatNumber(displayPrice, PRICE_DECIMALS)} </div>
          <div> {quoteAsset?.symbol}</div>
        </div>
      </div>
    </div>
  )
}

export const getContentForType = (notificationType: NotificationType, params: FixMe) => {
  switch (notificationType) {
    case NotificationType.DISPOSABLE:
    case NotificationType.SIMPLE:
      return generateSimpleNotification(params)
    case NotificationType.TOKEN:
      return generateTokenNotification(params.token)
    case NotificationType.ROLES:
      return generateRolesNotification(params.roles, params.user)
    case NotificationType.ADD_REMOVE_BALANCE:
      return generateAddRemoveBalanceNotification(params.selectedAsset, params.text, params.amount, params.isAdding)
    case NotificationType.ASSET_OPERATIONS:
      return generateAssetOperationsNotification(params.text, params.amount, params.asset)
    case NotificationType.CREATE_ASSET:
      return generateCreateAssetNotification(params.symbol)
    case NotificationType.CREATE_ORDER:
      return generateNewOrderNotification(
        params.orderID,
        params.selectedTradeType,
        params.selectedOrderSide,
        params.priceValue,
        params.leverageValue,
        params.baseAsset,
        params.quoteAsset,
        params.size,
        params.advancedType,
      )
    case NotificationType.CREATE_POOL:
      return generateCreatePoolNotification(
        params.baseAssetAmount,
        params.quoteAssetAmount,
        params.baseAssetID,
        params.quoteAssetID,
        params.swapFee,
      )
    case NotificationType.ORDER_CANCEL:
      return generateOrderCancelNotification(params.orderID)
    case NotificationType.ADD_LIQUIDITY:
      return generateAddLiquidityNotification(params.poolId, params.assets, params.allAssets)
    case NotificationType.REMOVE_LIQUIDITY:
      return generateRemoveLiquidityNotification(params.poolId, params.shares)
    case NotificationType.ORDER_EXECUTED_OR_CANCELED:
      return generateOrderStateChanged(params.order, params.baseAsset, params.quoteAsset, params.priceDisplayMultiplier)
    case NotificationType.REPAY:
      return generateRepayNotification(params.asset, params.amount)
    default:
      return <div>Unknown Operation Type</div>
  }
}

const onNotificationClose = () => {
  toast.close()
}

export function createNotification(
  title: string,
  params: FixMe,
  notificationType = NotificationType.SIMPLE,
  notificationStatus = NotificationStatus.SUCCESS,
) {
  const notificationItem: NotificationItem = {
    id: Date.now().toString(36),
    title: title,
    params: params,
    notificationStatus: notificationStatus,
    notificationType: notificationType,
    timestamp: Date.now(),
  }

  const notificationDiv = document.createElement('div')
  const notificationItemComponent = NotificationItemComponent({
    item: notificationItem,
    onNotificationClose,
  } as NotificationItemProps)
  const root = createRoot(notificationDiv)
  root.render(notificationItemComponent)

  toast
    .fire({
      html: notificationDiv,
      toast: true,
      timer: TOAST_TIMEOUT,
      position: 'top-end',
      showCloseButton: false,
      showConfirmButton: false,
      showCancelButton: false,
      scrollbarPadding: false,
      padding: '0px',
      background: 'transparent',
      customClass: {
        popup: 'p-0 m-0',
      },
    })
    .finally(() => {
      if (notificationType !== NotificationType.DISPOSABLE) {
        store.dispatch(addNotification(notificationItem))
      }
      root.unmount()
    })
}
