import { useEffect, useMemo, useState } from 'react'
import {
  LanguageCode,
  ResolutionString,
  widget,
  ChartingLibraryWidgetOptions,
  StudyEventType,
  EntityId,
  StudyOrDrawingAddedToChartEventParams,
  EntityInfo,
} from '@/tradingview/charting_library'
import type { TvWidget } from '@/constants/tvchart'
import { DEFAULT_RESOLUTION, PRICE_DECIMALS } from '@/utils/constants'
import { AppColorMode, AppTheme } from '@/hooks/tradingView/useTradingViewTheme'
import {
  getSupportedTimezone,
  getWidgetOptions,
  getWidgetOverrides,
  loadStudies,
  saveStudies,
} from '@/utils/tradingview'
import { getDatafeed } from '@/hooks/tradingView/dataFeed'
import { useAllAssets } from '@/hooks/useAsset'
import { useOrderBooks } from '@/hooks/useOrderBooks'
import { useOrderBookID } from '@/hooks/useOrderBookID'

/**
 * @description Hook to initialize TradingView Chart
 */
export const useTradingView = ({
  tvWidgetRef,
  displayButtonRef,
  setIsChartReady,
}: {
  tvWidgetRef: React.MutableRefObject<TvWidget | null>
  displayButtonRef: React.MutableRefObject<HTMLElement | null>
  setIsChartReady: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const appTheme = AppTheme.Dark
  const appColorMode = AppColorMode.GreenUp
  const [initialPriceScale, setInitialPriceScale] = useState<number | null>(null)
  const { allAssets } = useAllAssets()
  const { orderBooks } = useOrderBooks()
  const selectedLocale = 'en' as LanguageCode
  const { orderBookId } = useOrderBookID()

  const marketIds = useMemo(() => orderBooks.map((orderbook) => orderbook.orderBookID), [orderBooks.length])
  const hasMarkets = marketIds.length > 0
  const currentMarket = orderBooks.find((orderbook) => orderbook.orderBookID === orderBookId)
  const marketId = currentMarket?.orderBookID
  // TODO this currently is hardcoded to 2, but should be fixed, currently returning 0,001
  // const tickSizeDecimals = currentMarket?.tickSize
  const tickSizeDecimals = PRICE_DECIMALS

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const supportedTimezone = getSupportedTimezone(timezone) || 'Etc/UTC'

  useEffect(() => {
    console.log('supportedTimezone::::::::', supportedTimezone)
    if (hasMarkets && marketId) {
      const widgetOptions = getWidgetOptions()
      const widgetOverrides = getWidgetOverrides({ appTheme, appColorMode })
      const options = {
        ...widgetOptions,
        ...widgetOverrides,
        datafeed: getDatafeed(initialPriceScale, tickSizeDecimals, allAssets, orderBookId),
        interval: DEFAULT_RESOLUTION as ResolutionString,
        locale: selectedLocale,
        symbol: marketId,
        timezone: supportedTimezone,
      }

      const tvChartWidget = new widget(options as ChartingLibraryWidgetOptions)
      tvChartWidget.onChartReady(() => {
        const storedStudies = loadStudies(marketId)

        storedStudies.forEach((study: EntityInfo) => {
          tvChartWidget
            .activeChart()
            .createStudy(study.name, false, false)
            .catch((e) => {
              console.error('Failed to add study', e)
            })
        })

        tvChartWidget.subscribe('study_event', (event, studyEventType: StudyEventType) => {
          const studies = tvChartWidget.activeChart().getAllStudies()
          saveStudies(marketId, studies)
        })

        tvChartWidget.headerReady().then(() => {
          const button = tvChartWidget.createButton()
          button.setAttribute('title', 'Click to show a notification popup')
          button.classList.add('apply-common-tooltip')
          button.addEventListener('click', () =>
            tvChartWidget.showNoticeDialog({
              title: 'Notification',
              body: 'TradingView Charting Library API works correctly',
              callback: () => {
                console.log('Noticed!')
              },
            }),
          )
          button.innerHTML = 'Check API'
          setIsChartReady(true)
        })
      })
    }

    return () => {
      displayButtonRef.current?.remove()
      displayButtonRef.current = null
      tvWidgetRef.current?.remove()
      tvWidgetRef.current = null
      setIsChartReady(false)
    }
  }, [hasMarkets, selectedLocale, !!marketId, orderBookId])

  return {}
}
