import { useEffect } from 'react'
import App from '../../App'
import { toggleLayout } from '@/store/themeConfigSlice'
import { useDispatch } from 'react-redux'
import { Link, Outlet } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { TIME_DURATION } from '@/utils/constants'
import { useAppEnv } from '@/hooks/useAppEnv'

const BlankLayout = () => {
  const dispatch = useDispatch()
  const { appEnv } = useAppEnv()

  console.log('appEnv', appEnv)

  const { isError } = useQuery({
    queryKey: ['apiStatus'],
    queryFn: async () => {
      const response = await fetch(import.meta.env.VITE_APP_API_URL)
      console.log('response', response)
      if (!(response.status === 200)) {
        throw new Error('Network response was not ok')
      }
      return true
    },
    refetchInterval: TIME_DURATION.DAY,
    retry: false,
  })

  useEffect(() => {
    dispatch(toggleLayout('full'))
  }, [])

  if (isError) {
    return (
      <div className='relative flex min-h-screen items-center justify-center overflow-hidden'>
        <div className='relative text-center font-semibold '>
          <img src='/assets/images/404.svg' alt='404' className='mx-auto w-full max-w-[630px] object-cover' />
          <p className='mt-5 text-base dark:text-white'>API is down - try again later!</p>
          <a href={import.meta.env.VITE_APP_API_URL} className='text-primary visited:text-secondary ...'>
            {import.meta.env.VITE_APP_API_URL}
          </a>

          <Link to='/' className='font-bold btn btn-gradient mx-auto !mt-7 w-max border-0 uppercase shadow-none'>
            Home
          </Link>
        </div>
      </div>
    )
  }

  return (
    <App>
      <div className='text-black dark:text-white-dark min-h-screen '>
        <Outlet />
      </div>
    </App>
  )
}

export default BlankLayout
