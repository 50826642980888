import {
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Button } from '@/components/ui/button'
import { useAllUsers } from '@/hooks/useAllUsers'
import { tableColumnsUserRoles } from '@/components/administration/TableColumnsUserRoles'
import { useMutation } from '@tanstack/react-query'
import { getSdk, User, UserRole } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { queryClient } from '@/services/api'
import { useAllAssets } from '@/hooks/useAsset'
import { useMemo, useState } from 'react'

export function TableUserRoles() {
  const { allUsers } = useAllUsers()
  const { allAssets } = useAllAssets()
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const columns = tableColumnsUserRoles

  const validUsers = useMemo(() => allUsers.filter((user) => user?.uid.length > 0), [allUsers])

  const addBalanceToUserMutation = useMutation({
    mutationFn: ({ amount, assetID, userUID }: { amount: string; assetID: string; userUID: string }) =>
      getSdk(graphQLClient)
        .AddBalanceToUser({
          balanceToUserInput: {
            userUID: userUID,
            balance: {
              amount: amount,
              assetID: assetID,
            },
          },
        })
        .then(() => {
          // params.selectedAsset, params.text, params.amount, params.isAdding
          createNotification(
            'Add Balance',
            {
              selectedAsset: allAssets.find((a) => a.uid === assetID),
              text: 'Balance added successfully',
              amount: amount,
              isAdding: true,
            },
            NotificationType.ADD_REMOVE_BALANCE,
          )
        })
        .catch((err) => {
          const message = err?.message ?? 'Error on AddBalances'
          createNotification('Error on AddBalances', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['USER'] }).finally()
      queryClient.invalidateQueries({ queryKey: ['ALL_USERS'] }).finally()
    },
  })

  const updateUserRolesMutation = useMutation({
    mutationFn: ({ roles, uid }: { roles: UserRole[]; uid: string }) =>
      getSdk(graphQLClient)
        .UpdateUsersRoles({
          updatedRoles: {
            roles: roles,
            uid: uid,
          },
        })
        .then(() => {
          const user = validUsers.find((u) => u.uid === uid)
          createNotification('User ', { roles, user }, NotificationType.ROLES)
        })
        .catch((err) => {
          const message = err?.message ?? 'Error on UpdateUsersRoles'
          createNotification('Error on UpdateUsersRoles', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['USER'] }).finally()
      queryClient.invalidateQueries({ queryKey: ['ALL_USERS'] }).finally()
    },
  })

  const addBalanceToUser = (userUID: string, assetID: string, amount: string) => {
    addBalanceToUserMutation.mutate({ userUID, assetID, amount })
  }

  const updateUserRole = (role: UserRole, uid: string) => {
    const rolesArray: UserRole[] = Object.values(UserRole)
    const roleIndex = rolesArray.indexOf(role)

    if (roleIndex !== -1) {
      const roles = rolesArray.slice(0, roleIndex + 1)
      updateUserRolesMutation.mutate({ roles, uid: uid })
    }
  }

  // Custom function to filter by multiple columns
  const globalFilterFunction = (row: Row<User>, columnId: string, filterValue: string) => {
    const email = row.original.email || ''
    const name = row.original.name || ''
    const uid = row.original.uid || ''

    return (
      email.toLowerCase().includes(filterValue.toLowerCase()) ||
      name.toLowerCase().includes(filterValue.toLowerCase()) ||
      uid.toLowerCase().includes(filterValue.toLowerCase())
    )
  }

  const table = useReactTable({
    data: validUsers,
    columns,
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    meta: {
      updateUserRole,
      addBalanceToUser,
      allAssets,
    },
    onColumnFiltersChange: setColumnFilters, //<- important
    getFilteredRowModel: getFilteredRowModel(), //<- important
    state: {
      columnFilters, //<- important
    },
    globalFilterFn: globalFilterFunction, //<- important
  })

  const setFilter = (value: string) => {
    table.setGlobalFilter(value)
  }

  return (
    <div className='w-full panel dark:bg-card flex flex-col h-full'>
      <div className='relative flex justify-between items-center'>
        <h1 className={'dark:text-white text-lg pb-1 font-semibold'}>User Roles</h1>
        <div className='relative flex justify-end'>
          <input
            placeholder={'Search User'}
            onChange={(e) => setFilter(e.target.value)}
            className='text-sm my-2 w-[340px] relative rounded-sm shadow-sm form-input peer placeholder:tracking-widest border valid:border-dark text-right outline-none focus:ring-0 focus:ring-opacity-0 pl-2 !bg-black'
          />
          <span className='absolute left-2 top-1/2 transform -translate-y-1/2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              className='w-6 h-6 text-white-dark'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={1}
                d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
              />
            </svg>
          </span>
        </div>
      </div>

      <Table>
        <TableHeader className='text-sm'>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow className='p-1 ' key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    className='dark:bg-dark-darker border-b border-b-dark'
                    key={header.id}
                    style={{
                      width: header.getSize(),
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow className='p-1' key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell className={`border-b border-dark`} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow className='border p-1'>
              <TableCell colSpan={columns.length} className='text-center'>
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className='flex items-center justify-end space-x-2 py-4'>
        <Button variant='outline' size='sm' onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          Previous
        </Button>
        <div className={'text-xs'}>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </div>
        <Button variant='outline' size='sm' onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          Next
        </Button>
      </div>
    </div>
  )
}
