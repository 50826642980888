import { formatNumber } from '@/utils/numberUtils'

interface Props {
  onClose: () => void
  title: string
  from: {
    chain: string
    chainLogo: string
    asset: string
    assetLogo: string
  }
  maxBalance: string
  amount: string
  setAmount: (a: string) => void
  buttonText: string
}

const BridgeModal = ({ onClose, title, from, amount, setAmount, maxBalance, buttonText }: Props) => {
  return (
    <div className={'relative'} onClick={onClose}>
      <div className={'fixed bg-black/80 w-full h-full top-0 left-0 right-0 bottom-0'}>
        <div
          className={
            'w-96 p-8 bg-panel flex flex-col items-start gap-2 border border-dark absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-lg'
          }
          onClick={(e) => e.stopPropagation()}
        >
          <div className={'text-white text-xl font-semibold mb-4'}>{title}</div>
          <div className={'flex flex-col items-start gap-2 mb-4'}>
            <span className={'text-sm'}>Source Chain</span>
            <div className='flex gap-4'>
              <img alt='ethereumIcon' className={'w-8 h-8 p-0 m-0'} src={from.chainLogo} />
              <span className={'text-base text-white'}>{from.chain}</span>
            </div>
          </div>
          <div className={'flex flex-col items-start gap-2 mb-4'}>
            <span className={'text-sm'}>Asset</span>
            <div className='flex gap-4'>
              <img alt='usdcIcon' className={'w-8 h-8 p-0 m-0'} src={from.assetLogo} />
              <span className={'text-base text-white'}>{from.asset}</span>
            </div>
          </div>
          <div className={'flex flex-col items-start gap-2 mb-4 w-full'}>
            <span className={'text-sm'}>Amount</span>
            <input
              className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
              placeholder='0.000000'
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <div className='flex justify-between w-full items-start'>
              <div className={'text-sm'}>Balance: {maxBalance}</div>
              <button
                type='button'
                onClick={() => setAmount(maxBalance)}
                className='btn px-2 py-0 bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white border-0 shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
              >
                Max
              </button>
            </div>
          </div>
          <div className={'flex flex-col gap-2 my-4 w-full'}>
            <div className='flex justify-between items-center'>
              <span className={'text-base'}>Expected Deposit Amount</span>
            </div>
            <div className='flex justify-between items-center'>
              <span className={'text-base'}>Equity</span>
              <span className={'text-base text-white'}>{formatNumber(amount, 2, 'en', 'USD', true)}</span>
            </div>
            <div className='flex justify-between items-center'>
              <span className={'text-base'}>Buying Power</span>
              <span className={'text-base text-white'}>{formatNumber(amount, 2, 'en', 'USD', true)}</span>
            </div>
            <div className='flex justify-between items-center'>
              <span className={'text-base'}>Estimated Time</span>
              <span className={'text-base text-white'}>{'< 30 minutes'}</span>
            </div>
          </div>
          <button
            type='button'
            className='btn w-full bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white border-0 shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  )
}

export default BridgeModal
