import { useAccount, useReadContracts } from 'wagmi'
import React, { useState } from 'react'
import erc20Abi from '@/utils/abi/erc20.json'
import { USDC } from '@/configs/tokens'
import { formatUnits } from 'viem'

export const EthereumButton = ({
  setIsDepositOpen,
  setIsWithdrawOpen,
  isConnected,
  address,
  usdcBalanceResult,
  usdyBalanceResult,
  usdyAssetResult,
}: {
  setIsDepositOpen: (o: boolean) => void
  setIsWithdrawOpen: (o: boolean) => void
  isConnected: boolean
  address?: `0x${string}`
  usdcBalanceResult: any
  usdyBalanceResult: any
  usdyAssetResult: any
}) => {
  return (
    <>
      <div className={'w-full flex flex-row justify-center items-center gap-2'}>
        {!isConnected && (
          <img alt='ethereumIcon' className={'w-[32px] h-[32px] p-0 m-0'} src={'/assets/images/wallet/ethereum.svg'} />
        )}
        <div>
          <w3m-button />
        </div>
      </div>
      {isConnected && (
        <>
          <div className='flex justify-between items-end my-4'>
            <div className='flex flex-col items-start'>
              <div className='flex gap-2'>
                <span className='text-sm'>USDC Balance</span>
                <img
                  alt='ethereumIcon'
                  className={'w-[20px] h-[20px] p-0 m-0'}
                  src={'/assets/images/wallet/ethereum.svg'}
                />
              </div>
              <span className='text-xl text-white'>
                {formatUnits(usdcBalanceResult?.data?.[0] || '', USDC.decimals)}
              </span>
            </div>
            <button
              type='button'
              className='w-28 btn bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
              onClick={() => setIsDepositOpen(true)}
            >
              Deposit
            </button>
          </div>
          <div className='flex justify-between items-end my-4'>
            <div className='flex flex-col items-start'>
              <div className='flex gap-2'>
                <span className='text-sm'>USDY Balance</span>
                <img alt='ethereumIcon' className={'w-[20px] h-[20px] p-0 m-0'} src={'/favicon.png'} />
              </div>
              <span className='text-xl text-white'>
                {formatUnits(usdyBalanceResult?.amount || '', usdyAssetResult?.decimals || 2)}
              </span>
            </div>
            <button
              type='button'
              className='w-28 btn bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
              onClick={() => setIsWithdrawOpen(true)}
            >
              Withdraw
            </button>
          </div>
        </>
      )}
    </>
  )
}
